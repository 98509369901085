/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faPhoneAlt
} from '@fortawesome/free-solid-svg-icons'

import Button from 'elements/Button'
import H1 from 'elements/H1'
import Text from 'elements/Text'
import downArrow from 'images/icon/arrowPrimary.svg'
import successMark from 'images/icon/successMark.svg'
import ticketHttp from 'services/ticket'

import './index.scss'

const FirstBlock = ({ title, description, subBlocks, button }) => {
    const [fullName, setFullName] = useState('')
    const [status, setStatus] = useState('')
    const [email, setEmail] = useState('')
    const [msg, setMsg] = useState('')
    const [error, setError] = useState('')
    const onSubmit = async (e) => {
      e.preventDefault()
      if(!fullName || !email || !msg) {
        setError('*All fields are required.')
        return ;
      }
      setError('')
      setStatus('submitting')
      const result = await ticketHttp.postTicket(`FullName: ${fullName}, Email: ${email}, Message: ${msg}`)
      setStatus('success')
    }
    return (
      <div sx={{backgroundColor:'#F7F7F7'}}>
        <div className="contact-first-block">
          <div className="contact-1-inner-wrapper">
            <div  className="contact-info">
              <H1 className="blog-fourth-title" color="primary">{title}</H1>
              <Text size="medium-minus" color="black" family="Univers-LT-Std-45-Light">{description?.description}</Text>
              <a sx={{color: 'primary'}} href={subBlocks[0]?.path}>
                <FontAwesomeIcon icon={faEnvelope} />
                <span>{subBlocks[0]?.label}</span>
              </a>
            </div>
            <div className="form-wrapper">
              {
                status !== 'success' && (
                  <form action={button?.path} className={`contact-form ${status === 'submitting' ? 'loading' : ''}`}>
                    {error && <span className="error-panel">{error}</span>}
                    <div className="contact-form-body">
                      <fieldset disabled={status === 'submitting'}>
                        <input type="text" placeholder="Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)}/>
                        <input type="email" placeholder="Email" value={email} pattern="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/" onChange={(e) => setEmail(e.target.value)}/>
                        <textarea type="text" placeholder="Tell us more" value={msg} onChange={(e) => setMsg(e.target.value)} style={{resize:'none'}}/>
                      </fieldset>
                    </div>
                    <Button className="contact-1-submit-btn" >
                      <div
                      type="submit" 
                      onClick={onSubmit}
                      >Sign Up
                      </div>
                    </Button>
                  </form>
                )
              }
              {status === 'success' && (
                <div className="get-first-body get-first-body-success">
                  <div className="success-mark">
                    <img src={successMark} alt="" />
                  </div>
                  <H1 size="large-minus" color="#65ae78">
                    Congratulations!
                  </H1>
                  <Text size="small-plus" family="Pangram">
                    You have successfully signed up!
                  </Text>
                </div>
              )}
            </div>
            <a href="#second-block" className="down-arrow"><img src={downArrow} alt="" /></a>
          </div>
        </div>
      </div>
    )
  }

export default FirstBlock